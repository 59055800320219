<template>
  <v-app id="inspire" :key="componentKey">
    <v-app-bar app>
      <v-row>
        <v-col cols="2">
            <v-img :src="logo1" max-height="44" max-width="171"/>
        </v-col>
        <v-col cols="8">
            <h1 class="text-center">&nbsp;{{portalTitleLabel}}</h1>
        </v-col>
        <v-col cols="2" >
            <div height="44px" width="179px">
            <v-img :src="logo2" class="ml-auto" height="44px" width="179px" contain/>
            </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
            <v-file-input accept=".json"
                            label="Select files"
                            v-model="files"
                            @change="addFiles">
                            </v-file-input>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
  <!-- Row for displaying form -->
  <v-row justify="center" v-show=(!showConfirmation)>
                <v-col
                  class="text-center"
                  cols="12"
                >
                 <p style="padding-top: 2em">{{welcomeTextLabel}}</p>
                </v-col>
  </v-row>
  <v-row justify="center" v-show=(!showConfirmation)>
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6"
    >
      <v-card ref="form">
        <v-card-text>
        <v-container fluid>
            <v-row>
                <v-col>
                    <v-autocomplete
                      ref="searchPlaceAutoComplete"
                      cache-items
                      v-model="selectedPlace"
                      :items="placeIdList"
                      :loading="isLoading"
                      :search-input.sync="searchPlace"
                      color="white"
                      hide-no-data
                      hide-selected
                      item-text="Description"
                      item-value="Place_id"
                      :label=addressLookupLabel
                      placeholder="Start typing to Search"
                      return-object
                      :disabled=disableFlag
                      solo
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row v-show="((this.predictionList.pointInfoList != null)&&(this.predictionList.pointInfoList.length > 1))">
               <v-col>
                    <v-radio-group
                            v-model="selectedAddress"
                            column
                    >
                                    <span v-for= "item in predictionList.pointInfoList" v-bind:key="item">
                                    <v-radio
                                              :label=item.description
                                              :value=item
                                              :disabled=disableFlag
                                    ></v-radio>
                                    </span>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row v-show="((this.predictionList.isValidAddress == false))">
                <v-col>
                        {{incorrectCouncilErrorMsg}}
                </v-col>
            </v-row>
            <v-row v-show="(((this.predictionList.pointInfoList!= null)&&(this.predictionList.pointInfoList.length == 0)))">
                <v-col>
                        {{propertyNotFoundErrorMsg}}
                </v-col>
            </v-row>

                <v-container fluid v-show="((this.isHardwareOnDemandAvailable == false) && (this.isScheduled != true))">
                    <p>
                        {{noHardwasteOnDemandErrorMsg}}
                    </p>
                </v-container>
                 <v-container fluid v-show="this.isScheduled == true">
                    <p>{{scheduledPickupErrorMessage}}</p>
                 </v-container>
                 <v-container fluid v-show="(this.isHardwareOnDemandAvailable == false) ||
                                            (this.isNoMoreAllocationsRemain == true) ||
                                            (this.complexAllocations && (this.allocationSummaryList!=null) && (this.allocationSummaryList.length == 0))">
                    <p v-show="((this.isNoMoreAllocationsRemain == true) && (this.isHardwareOnDemandAvailable != false))">
                        {{invalidCollectionsErrorMsg}}
                    </p>

                 </v-container>
                 <v-container fluid v-show="((this.isScheduled != true) && (!this.isServiceDenialFlagPresent) && (this.isNoMoreAllocationsRemain!=true))">
                             <p v-if="(this.currentYearRemaining||this.nextYearRemaining||((this.complexAllocations)&&(this.allocationSummaryList != null) && (this.allocationSummaryList.length > 0)))">{{bookingHistoryLabel}}</p>
                             <p v-if="(this.currentYearRemaining||this.nextYearRemaining)">
                                 {{this.currentYearMessage}}
                             </p>
                             <p v-if="(this.currentYearRemaining||this.nextYearRemaining)">
                                 {{this.nextYearMessage}}
                             </p>

                             <p v-if="((this.complexAllocations) && (this.allocationSummaryList != null) && (this.allocationSummaryList.length > 0))">

                                    <v-row no-gutters>
                                            <v-col md="3">
                                                <v-card
                                                    class="pa-2"
                                                    outlined
                                                    tile
                                                >
                                               Waste Type
                                                </v-card>
                                            </v-col>
                                            <v-col md="3">
                                                <v-card
                                                    class="pa-2"
                                                    outlined
                                                    tile
                                                >
                                                {{bookingHeaderRemainingLabel}}
                                                </v-card>
                                            </v-col>
                                            <v-col md="3">
                                                <v-card
                                                    class="pa-2"
                                                    outlined
                                                    tile
                                                >
                                                  {{bookingHeaderNextLabel}}
                                                </v-card>
                                            </v-col>
                                    </v-row>
                                    <span v-for="item in allocationSummaryList" v-bind:key="item">
                                        <v-row no-gutters>
                                            <v-col md="3">
                                                <v-card
                                                    class="pa-2"
                                                    outlined
                                                    tile
                                                >
                                                {{item.allocationName}}
                                                </v-card>
                                            </v-col>
                                            <v-col md="3">
                                                <v-card
                                                    class="pa-2"
                                                    outlined
                                                    tile
                                                >
                                                {{item.currentYearRemaining}}
                                                </v-card>
                                            </v-col>
                                            <v-col md="3">
                                                <v-card
                                                    class="pa-2"
                                                    outlined
                                                    tile
                                                >
                                                {{item.nextYearRemaining}}
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </span>
                             </p>
                             <p v-if="(this.sizeRequired == true)">
                             <span v-for="item in eventList" v-bind:key="item">
                                 <v-row no-gutters>
                                     <v-col md="3">
                                         <v-card
                                             class="pa-2"
                                             outlined
                                             tile
                                         >
                                         {{item.date}}
                                         </v-card>
                                     </v-col>
                                     <v-col md="3">
                                         <v-card
                                             class="pa-2"
                                             outlined
                                             tile
                                         >
                                            {{item.size}}
                                         </v-card>
                                     </v-col>
                                     <v-col md="3">
                                         <v-card
                                             class="pa-2"
                                             outlined
                                             tile
                                         >
                                         {{item.status}}
                                         </v-card>
                                     </v-col>
                                     <v-col md="3" v-if = "(item.status == 'Allocated to Crew') && (enableCancel) && (showCancel(item.date))">

                                         <div class = "d-flex justify-center">
                                             <v-btn variant="tonal" color ="red" @click="cancelDialog = true">
                                                 Cancel
                                             </v-btn>
                                         </div>
                                     </v-col>
                                 </v-row>
                             </span>
                             </p>
                             <p v-if="(this.sizeRequired == false)">
                             <span v-for="item in eventList" v-bind:key="item">
                                 <v-row no-gutters>
                                     <v-col md="3">
                                         <v-card
                                             class="pa-2"
                                             outlined
                                             tile
                                         >
                                         {{item.date}}
                                         </v-card>
                                     </v-col>
                                     <v-col md="3">
                                         <v-card
                                             class="pa-2"
                                             outlined
                                             tile
                                         >
                                            {{item.wasteTypesDisplay}}
                                         </v-card>
                                     </v-col>
                                     <v-col md="3">
                                         <v-card
                                             class="pa-2"
                                             outlined
                                             tile
                                         >
                                         {{item.status}}
                                         </v-card>
                                     </v-col>
                                     <v-col md="3" v-if = "(item.status == 'Allocated to Crew') && (enableCancel) && (showCancel(item.date))">

                                         <div class = "d-flex justify-center">
                                             <v-btn variant="tonal" color ="red" @click="cancelDialog = true">
                                                 Cancel
                                             </v-btn>
                                         </div>
                                     </v-col>
                                 </v-row>
                             </span>
                             </p>

                                         <v-dialog width = "auto" v-model ="cancelDialog" persistent :retain-focus="false">
                                                 <v-card max-width="600">
                                                     <v-card-title>
                                                         {{cancelBookingTitle}}
                                                     </v-card-title>
                                                     <v-divider></v-divider>
                                                     <v-card-text class="bg-surface-light pt-4">
                                                        {{cancelBookingMessage}}
                                                     </v-card-text>
                                                     <v-card-actions>
                                                         <v-text-field
                                                             max-width="300"
                                                             label="Booking Reference"
                                                             varient ="outlined"
                                                             v-model="cancelEventId"
                                                             ref="cancelEventId"
                                                             :rules="[
                                                                        () => !!cancelEventId || 'This field is required',
                                                                        () => (!!cancelEventId && validateEventId) || 'Invalid Booking Reference'
                                                                     ]"
                                                         ></v-text-field>
                                                     </v-card-actions>
                                                     <div class = "d-flex justify-center">
                                                         <v-card-actions>
                                                             <v-btn variant = "tonal" color = "blue" @click="cancel()">
                                                                 Confirm
                                                             </v-btn>
                                                             <v-btn variant = "tonal" color = "grey" @click="cancelDialog = false">
                                                                 Close
                                                             </v-btn>
                                                         </v-card-actions>
                                                     </div>
                                                 </v-card>
                                         </v-dialog>

                 </v-container>
        </v-container>
        <v-container fluid v-show="((this.currentPageNumber >= 2) && (!this.isServiceDenialFlagPresent))">
          <v-text-field
            ref="strataNumber"
            v-model="strataNumber"
            :rules="[
              () => !!strataNumber || 'This field is required',
              () => !!strataNumber && strataNumber.length <= 25 || 'Address must be less than 25 characters',
              () => !!(this.selectedStrataNumber == strataNumber) || strataNumberErrorMsg
            ]"
            :label=strataPlanNumberLabel
            placeholder=""
            counter="25"
            required
            v-show=(selectedStrataNumber)
            :disabled=disableFlag
            :onchange = "setPageNumber()"
            outlined
          ></v-text-field>
        </v-container>
        <v-container fluid v-show="(this.currentPageNumber >= 3)&&(this.showWasteTypes) && (this.isHardwareOnDemandAvailable) && ((this.currentYearRemaining>0||this.nextYearRemaining>0) || (this.hasComplexAllocationAvailable)) ">
             <div v-show="(!this.isServiceDenialFlagPresent)">
                <p>{{ wasteTypesLabel }}</p>
                <div v-for="(item, index) in wasteTypesList" :key="index">
                        <v-checkbox
                        v-model="selectedWasteTypes"
                        :label=item.name
                        :value=item.id
                        :disabled="isWasteTypeItemDisabled(item.id, item.exclusiveID)"
                        :rules="validateWasteTypes"
                        :onchange = "setPageNumber()"
                        ></v-checkbox>
                        <div v-if="(item.qtyRequired == true)">
                            <v-text-field
                                ref="numberOfMattresses"
                                v-model="item.qtyVal"
                                :rules="[
                                    () => !!((((/^[1-9][0-9]?$|^item.qtyMax$/.test(item.qtyVal)))&&(item.qtyVal <= item.qtyMax))) || item.qtyError]"
                                :error-messages="errorMessages"
                                :label=numberOfMattressesLabel
                                placeholder="Type number of mattresses"
                                v-show="showQtyNumberField"
                                :onchange = "setPageNumber()"
                                :disabled=disableFlag
                            >
                            </v-text-field>
                        </div>
                </div>
             </div>
             <p v-if=(this.isServiceDenialFlagPresent)>{{this.serviceDenialErrorMessage}}</p>

        </v-container>
        <v-container fluid v-show="this.currentPageNumber >= 4 && this.isQtyValid">
            <v-row v-show="sizeRequired">
                <v-col md="5">
            <v-select
                ref="size"
                v-model="selectedSize"
                :items="sizeList"
                :label="sizeLabel"
                item-text="name"
                item-value="id"
                :rules="[
                    () => !!selectedSize || 'This field is required'
                ]"
                solo
                :disabled=disableFlag
                :onchange = "setPageNumber()"
            ></v-select>
                </v-col>
                <v-col md="7">
                    <v-img :src="sizeImg"/>
                </v-col>

            </v-row>
            <v-row v-show="this.isShowNotEnoughCollectionsError == true">
                <v-col>
                    {{invalidCollectionsErrorMsg}}
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        btn
                        large
                        color="primary"
                        v-on:click=resetSearch
                        v-show=(this.disableFlag)
                        >
                        Restart
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid v-show="((this.currentPageNumber >= 5) && (this.isQtyValid) && (this.isNoMoreAllocationsRemain!=true)) ">
            <v-row v-show="this.firstDatePicker">
              <v-col md="12">
                    <v-row>
                        <v-subheader>
                            {{firstDateOfBookingLabel}}
                        </v-subheader>
                    </v-row>
                    <v-row>
                        <v-col md="6">
                            <v-date-picker
                                v-model="selectedMonth"
                                :allowed-dates="allowedMonthsSkip"
                                type="month"
                                :show-current="false"
                            ></v-date-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="2">
                            <v-btn
                                btn
                                large
                                color="primary"
                                :loading="isLoading"
                                :disabled="!this.selectedMonth"
                                v-on:click="searchForSlots(selectedMonth,1)"
                                v-show=(true)
                            >
                                Search
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="12">
                            <span v-if = "(sameDayBookingFlag)">
                                <span v-if="(this.availableSlots!=null)&&(this.availableSlotsList.length > 0)">{{availableDatesLabel}}</span>
                                <span v-if="(this.availableSlots!=null)&&(this.availableSlotsList.length <= 0)">{{notAvailableDatesLabel}}</span>
                            </span>
                            <span v-if = "(!sameDayBookingFlag)">
                                <span v-if="(this.sameDayBookingList()!=null)&&(this.sameDayBookingList().length > 0)">{{availableDatesLabel}}</span>
                                <span v-if="(this.sameDayBookingList()!=null)&&(this.sameDayBookingList().length <= 0)">{{notAvailableDatesLabel}}</span>
                            </span>

                            <v-radio-group
                                    v-model="selectedSlot"
                                    column
                            >
                                    <span v-if = "(!sameDayBookingFlag) && sameDayBookingList() !=null">
                                        <span v-if = "sameDayBookingList().length > 0">
                                            <span v-for= "item in sameDayBookingList()" v-bind:key="item">
                                                <v-radio
                                                    :label=item.endDate
                                                    :value=item.referenceNumberList
                                                    :onchange = "setPageNumber()"
                                                ></v-radio>
                                            </span>
                                        </span>
                                    </span>

                                    <span v-if = "(sameDayBookingFlag) && this.availableSlots!=null">
                                        <span v-if = "availableSlotsList.length > 0">
                                            <span v-for= "item in availableSlotsList" v-bind:key="item">
                                                <v-radio
                                                    :label=item.endDate
                                                    :value=item.referenceNumberList
                                                    :onchange = "setPageNumber()"
                                                ></v-radio>
                                            </span>
                                        </span>
                                    </span>
                            </v-radio-group>
                        </v-col>
                    </v-row>
              </v-col>
            </v-row>
            <v-row v-show="this.secondDatePicker">
                <v-col md="12">
                    <v-row>
                        <v-subheader>
                            {{secondDateOfBookingLabel}}
                        </v-subheader>
                    </v-row>
                    <v-row>
                        <v-col md="6">
                            <v-date-picker
                                v-model="selectedSecondMonth"
                                :allowed-dates="allowedMonthsLoose"
                                type="month"
                                :show-current="false"
                            ></v-date-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="2">
                            <v-btn
                                btn
                                large
                                color="primary"
                                :loading="isLoading"
                                :disabled="!this.selectedSecondMonth"
                                v-on:click="searchForSlots(selectedSecondMonth, 2)"
                                v-show=(true)
                            >
                                Search
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="12">
                            <span v-if="(this.secondAvailableSlots!=null)&&(this.secondAvailableSlotsList.length > 0)">{{availableDatesLabel}}</span>
                            <span v-if="(this.secondAvailableSlots!=null)&&(this.secondAvailableSlotsList.length <= 0)">{{notAvailableDatesLabel}}</span>
                            <v-radio-group
                                    v-model="secondSelectedSlot"
                                    column
                            >
                                    <span v-for= "item in secondAvailableSlotsList" v-bind:key="item">
                                            <v-radio
                                              :label=item.endDate
                                              :value=item.referenceNumberList
                                              :onchange = "setPageNumber()"
                                            ></v-radio>
                                    </span>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
         </v-container>
         <v-container fluid v-show="this.currentPageNumber >= 6 && (this.selectedSlot||this.secondSelectedSlot)">
            <v-text-field
                ref="firstName"
                v-model="firstName"
                :rules="[() => !!firstName || 'This field is required']"
                :error-messages="errorMessages"
                placeholder="Type firstName"
                v-show=(!selectedStrataNumber)
                outlined
            >
                            <template v-slot:label>
                                <div>{{firstNameLabel}}<span style="color:red">*</span></div>
                            </template>
            </v-text-field>
            <v-text-field
                ref="surname"
                v-model="surname"
                :rules="[() => !!surname || 'This field is required']"
                :error-messages="errorMessages"
                placeholder="Type surname"
                v-show=(!selectedStrataNumber)
                outlined
            >
                            <template v-slot:label>
                                <div>{{surnameLabel}}<span style="color:red">*</span></div>
                            </template>

            </v-text-field>
            <v-text-field
                ref="strataManager"
                v-model="strataManager"
                :rules="[() => !!strataManager || 'This field is required']"
                :error-messages="errorMessages"

                placeholder="Type strata manager or company name"
                v-show=(selectedStrataNumber)
                outlined
                >
                            <template v-slot:label>
                                <div>{{strataManagerLabel}}<span style="color:red">*</span></div>
                            </template>

                </v-text-field>
            <v-checkbox
                ref="strataAuthorisationFlag"
                v-model="strataAuthorisationFlag"
                :onchange = "setPageNumber()"
                :error-messages="errorMessages"
                :rules="[() => !!strataAuthorisationFlag || 'This field is required']">

                            <template v-slot:label>
                                <div>{{strataAuthorisationFlagLabel}}<span style="color:red">*</span></div>
                            </template>

            </v-checkbox>
            <v-row v-show=(displayStickerLetterTickbox)>
                <v-col>
                    <v-checkbox
                        ref="nonStrataRequireStickerFlag"
                        v-model="nonStrataRequireStickerFlag"
                        :error-messages="errorMessages"
                        :label=nonStrataRequireStickerFlagLabel
                        v-show=(!selectedStrataNumber)>
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row v-show=(displayStickerLetterTickboxStrata)>
                <v-col>
                    <v-checkbox
                        ref="strataRequireStickerFlag"
                        v-model="strataRequireStickerFlag"
                        :error-messages="errorMessages"
                        :label=strataRequireStickerFlagLabel
                        v-show=(selectedStrataNumber)>
                    </v-checkbox>
                </v-col>
            </v-row>
      </v-container>
      <v-container fluid v-show="this.currentPageNumber >= 7 && (this.selectedSlot||this.secondSelectedSlot)">
            <v-row v-show=(showPostalAddress)>
                <v-col>
                    <v-checkbox
                        ref="postalAddressFlag"
                        v-model="postalAddressFlag"
                        :error-messages="errorMessages"
                        :label=postalAddressFlagLabel
                        v-show=(!selectedStrataNumber)>
                    </v-checkbox>
                    <v-checkbox
                        ref="postalAddressPOBoxFlag"
                        v-model="postalAddressPOBoxFlag"
                        :error-messages="errorMessages"
                        :label=postalAddressPOBoxFlagLabel
                        v-show=(postalAddressFlag)>
                    </v-checkbox>
                    <v-text-field
                                    ref="postalAddressPOBox"
                                    v-model="postalAddressPOBox"
                                    :rules="[() => !!postalAddressPOBox || 'This field is required']"
                                    :error-messages="errorMessages"
                                    placeholder="Type PO Box address"
                                    v-show=(postalAddressPOBoxFlag)
                                    outlined
                    />
                    <v-autocomplete
                      v-model="selectedPostalPlace"
                      :items="postalPlaceIdList"
                      :loading="isLoading"
                      :search-input.sync="searchPostalPlace"
                      color="white"
                      hide-no-data
                      hide-selected
                      item-text="Description"
                      item-value="Place_id"
                      placeholder="Start typing to Search"
                      return-object
                      v-show=((postalAddressFlag)&&(!postalAddressPOBoxFlag))
                      :rules="validatePostalAddress"
                      solo
                    >
                            <template v-slot:label>
                                <div>{{postalAddressLookupLabel}}<span style="color:red">*</span></div>
                            </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row v-show="((showPostalAddress)&&(this.postalPredictionList.pointInfoList != null)&&(this.postalPredictionList.pointInfoList.length > 1))">
               <v-col>
                    <v-radio-group
                            v-model="selectedPostalAddress"
                            column
                    >
                                    <span v-for= "item in postalPredictionList.pointInfoList" v-bind:key="item">
                                    <v-radio
                                              :label=item.description
                                              :value=item
                                    ></v-radio>
                                    </span>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                <v-text-field
                    ref="phoneNumber"
                    v-model="phoneNumber"
                      :rules="[
                        () => !!phoneNumber || 'This field is required',
                        () => !!(phoneNumber && phoneNumber.length != 8) || 'Phone number must be 10 digits',
                        () => !!(/^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/.test(phoneNumber))||this.phoneNumberErrorLabel
                      ]"
                      placeholder="Type phone number"
                      counter="10"
                      required
                      outlined
                >
                            <template v-slot:label>
                                <div>{{phoneNumberLabel}}<span style="color:red">*</span></div>
                            </template>

                </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                <v-text-field
                    ref="email"
                    v-model="email"
                      :rules="[
                        () => !!email || 'This field is required',
                        () => !!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]*@([\w-]+)*(\.\w{2,})*$/.test(email))||this.emailErrorLabel
                      ]"
                      placeholder="Type email"
                      required
                      outlined
                >
                            <template v-slot:label>
                                <div>{{emailLabel}}<span style="color:red">*</span></div>
                            </template>

                </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-textarea
                     outlined
                     ref="collectionInstructions"
                     :label=collectionInstructionsLabel
                     v-model="collectionInstructions"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                <div v-html="termsConditionsContent"></div>

                <v-checkbox
                    ref="termsConditionsFlag"
                    v-model="termsConditionsFlag"
                    :error-messages="errorMessages"
                >
                            <template v-slot:label>
                                <div>{{termsConditionsFlagLabel}}<span style="color:red">*</span></div>
                            </template>
                </v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col>

                    <v-checkbox
                    ref="privacyFlag"
                    v-model="privacyFlag"
                    :error-messages="errorMessages"
                    >
                            <template v-slot:label>
                                <div>{{privacyFlagLabel}}<span style="color:red">*</span></div>
                            </template>
                    </v-checkbox>
                    <div v-html="privacyLabel" style="margin-left: 30px;"></div>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-slide-x-reverse-transition>
            <v-tooltip
              v-if="formHasErrors"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="my-0"
                  v-bind="attrs"
                  @click="resetForm"
                  v-on="on"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh form</span>
            </v-tooltip>
          </v-slide-x-reverse-transition>
          <v-btn
            color="primary"
            text
            :loading="isLoading"
            @click="submit"
            :disabled="!showSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

  <!-- Row for showing confirming page -->
  <v-row justify="center" v-show=(showConfirmation)>
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6"
    >
        <p v-if=(!selectedStrataNumber)>Dear {{this.firstName}} {{this.surname}},</p>
        <p v-else>Dear {{this.strataManager}}, </p>

        <div v-html="submitConfirmationContentTop"></div>
        <div v-html="submitConfirmationContentMiddle" v-show=(this.selectedDate2)></div>
        <div v-html="submitConfirmationContentBottom"></div>
    </v-col>
  </v-row>
  <v-row v-show=(showConfirmation)>
        <v-col
          class="text-center"
          cols="12"
        >
           <div v-html="anotherBookingLabel"/>
        </v-col>
  </v-row>
    </v-main>
      <v-footer padless>
        <v-col
          class="text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong> {{this.councilLabel}}</strong>
        </v-col>
      </v-footer>
  </v-app>
</template>

<script>
import labelData from "../assets/organizations.json";

export default {
  name: 'Main',
  data() {
    return{
      componentKey: 32,
      files: null,
      sessionId: null,
      selectedPrediction: null,
      addressSearch:null,
      predictionList:[],
      postalPredictionList:[],
      currentPageNumber: 1,
      msg: '',
      labels : labelData,
      errorMessages: '',
      addrLookup: null,
      strataNumber: null,
      selectedMonth: null,
      selectedSecondMonth: null,
      firstName: null,
      surname: null,
      strataManager:null,
      strataAuthorisationFlag:null,
      strataRequireStickerFlag:null,
      nonStrataRequireStickerFlag:null,
      postalAddressFlag: null,
      postalAddressPOBoxFlag:null,
      postalAddressPOBox:null,
      selectedPostalAddress: null,
      zip: null,
      country: null,
      formHasErrors: false,
      selectedPlace:null,
      selectedPostalPlace: null,
      selectedAddress : null,
      descriptionLimit: 60,
      lookupList: [],
      postalLookupList:[],
      pointInfoList:[],
      postalPointInfoList:[],
      isLoading: false,
      isPostalLoading: false,
      searchPlace: null,
      searchPostalPlace:null,
      search: null,
      postalSearch: null,
      selectedStrataNumber: null,
      phoneNumber: null,
      email: null,
      numberOfMattresses: 0,
      collectionInstructions: null,
      termsConditionsFlag: null,
      privacyFlag:null,
      selectedWasteTypes : [],
      selectedSlot : null,
      secondSelectedSlot : null,
      availableSlots : null,
      secondAvailableSlots : null,
      disableFlag : false,
      reservationGuid : null,
      postResponse: null,
      selectedSize : null,
      eventList : [],
      isCurrentYearAvailableForBooking: null,
      isNextYearAvailableForBooking: null,
      currentYearRemaining: null,
      nextYearRemaining: null,
      currentYearRemainingLoose: null,
      nextYearRemainingLoose: null,
      isScheduled: null,
      scheduledDateStr : null,
      allocationSummaryList : null,
      isHardwareOnDemandAvailable : null,
      showWasteTypes:false,
      isShowNotEnoughCollectionsError : null,
      cancelDialog:false,
      cancelEventId:null
    }
  },
  watch: {
      name () {
        this.errorMessages = ''
      },
      selectedPostalPlace() {
            this.isLoading = true
            var input = {"placeId": this.selectedPostalPlace.place_id, "isPostalAddress": true, "addressStr": this.selectedPostalPlace.description, "echoAPIUsername": this.echoAPIUsername, "googleAPIKey": this.googleAPIKey, "councilLabel": this.councilLabel}
            // Lazily load input items
            fetch('/api/messages/addressLookupDetail',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(input)
                }
            )
              .then(res => res.json())
              .then(res => {
                this.postalPredictionList = res
                if (this.postalPredictionList.pointInfoList.length == 1) {
                    this.selectedPostalAddress = this.postalPredictionList.pointInfoList[0]
                }
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false))

      },
      selectedPlace() {
            this.isLoading = true
            var input = {"placeId": this.selectedPlace.place_id,
                         "addressStr": this.selectedPlace.description,
                         "echoAPIUsername": this.echoAPIUsername,
                         "googleAPIKey": this.googleAPIKey,
                         "councilLabel": this.councilLabel}
            // Lazily load input items
            fetch('/api/messages/addressLookupDetail',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(input)
                }
            )
              .then(res => res.json())
              .then(res => {
                this.predictionList = res
                if (this.predictionList.pointInfoList.length == 1) {
                    this.selectedAddress = this.predictionList.pointInfoList[0]
                }
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false))

      },
      selectedAddress() {
        //console.log("Selected Address got changed")

        this.currentPageNumber = 1
        this.availableSlots = null
        this.secondAvailableSlots = null
        this.selectedStrataNumber= null
        this.strataNumber = null

        var currentYearUsedId
        var currentYearAllowedId
        var nextYearUsedId
        var nextYearAllowedId
        //var parentId
        //var yearId

        if (this.complexAllocations == false) {
            currentYearUsedId = this.allocationsThisYear.idUsed
            currentYearAllowedId = this.allocationsThisYear.idAllowed
            nextYearUsedId = this.allocationsNextYear.idUsed
            nextYearAllowedId = this.allocationsNextYear.idAllowed
        }

        var input = {"pointAddressId": this.selectedAddress.id,  "echoAPIUsername": this.echoAPIUsername, "scheduledServiceId" : this.scheduledServiceId, "onDemandServiceId": this.onDemandServiceId,
                     "currentYearUsedId" : currentYearUsedId, "currentYearAllowedId" : currentYearAllowedId,
                     "nextYearUsedId": nextYearUsedId, "nextYearAllowedId": nextYearAllowedId,
                     "strataDataTypeId" : this.strataDataTypeId,
                     "complexAllocationList" : this.getComplexAllocationList,
                     "defaultFallbackTitle": this.defaultFallbackTitle}
        fetch('/api/messages/getServiceUnitsForObject',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(input)
                }
        )
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if (Object.keys(res).length === 0) {
                this.isHardwareOnDemandAvailable = false
            } else {
                if ((res.currentYearRemaining == null && res.nextYearRemaining == null) && (res.allocationSummaryList != null) && (res.allocationSummaryList.length == 0)) {
                    this.isHardwareOnDemandAvailable = false
                } else {
                    this.isHardwareOnDemandAvailable = true
                }
            }
            console.log(this.isHardwareOnDemandAvailable)

            this.selectedStrataNumber = res.strataNumber
            this.isCurrentYearAvailableForBooking = res.isCurrentYearAvailableForBooking
            this.isNextYearAvailableForBooking = res.isNextYearAvailableForBooking
            this.currentYearRemaining = res.currentYearRemaining
            if ((!this.currentYearRemaining) && ((res.allocationSummaryList) && (res.allocationSummaryList.length > 0))) {
                var skipRemainingCount = 0
                var looseRemainingCount = 0;
                for (let i = 0; i < res.allocationSummaryList.length; i++) {
                    var allocation = res.allocationSummaryList[i];
                    var parentId = allocation.allocationId
                    var isLoose = this.isLooseItem(parentId)
                    if (isLoose) {
                        looseRemainingCount+=allocation.currentYearRemaining
                    } else {
                        skipRemainingCount+=allocation.currentYearRemaining
                    }
                }

                this.currentYearRemaining = skipRemainingCount
                this.currentYearRemainingLoose = looseRemainingCount

                //console.log("currentYearRemaining=" + this.currentYearRemaining)
                //console.log("currentYearRemainingLoose=" + this.currentYearRemainingLoose)

            }

            this.nextYearRemaining = res.nextYearRemaining
            if ((!this.nextYearRemaining) && ((res.allocationSummaryList) && (res.allocationSummaryList.length > 0))) {
                skipRemainingCount = 0
                looseRemainingCount = 0;
                for (let i = 0; i < res.allocationSummaryList.length; i++) {
                    allocation = res.allocationSummaryList[i];
                    parentId = allocation.allocationId
                    isLoose = this.isLooseItem(parentId)
                    if (isLoose) {
                        looseRemainingCount+=allocation.nextYearRemaining
                    } else {
                        skipRemainingCount+=allocation.nextYearRemaining
                    }
                }

                this.nextYearRemaining = skipRemainingCount
                this.nextYearRemainingLoose = looseRemainingCount

                console.log("nextYearRemaining1=" + this.nextYearRemaining)
                console.log("nextYearRemainingLoose=" + this.nextYearRemainingLoose)
                console.log("@@@@@")
            }

            this.isScheduled = res.isScheduled
            this.scheduledDateStr = res.scheduledDateStr
            this.allocationSummaryList = this.orderAllocationSummaryList(res.allocationSummaryList)
            if (this.isScheduled) {
                this.currentPageNumber = 1
            } else {
                if ((this.isHardwareOnDemandAvailable == false)) {
                    this.currentPageNumber = 1
                } else {
                    if (this.selectedStrataNumber == null) {
                        this.currentPageNumber = 3
                    } else {
                        this.currentPageNumber = 2
                    }
                }
            }
        })
        .catch(err => {
                console.log(err)
        })

        var inputForGetEventsForObject = {};
        inputForGetEventsForObject.pointAddressId = this.selectedAddress.id
        inputForGetEventsForObject.echoAPIUsername = this.echoAPIUsername
        inputForGetEventsForObject.wasteTypeIds = this.wasteTypesIdList
        inputForGetEventsForObject.eventTypeId = this.eventTypeId
        inputForGetEventsForObject.eventStateList = this.eventStates
        inputForGetEventsForObject.sizeDataTypeId = this.sizeDataTypeId
        inputForGetEventsForObject.sizeLabelList = this.sizeLabelList
        inputForGetEventsForObject.isFinancialYear = this.usingFinancialYear
        fetch('/api/messages/getEventsForObject',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(inputForGetEventsForObject)
                }
        )
        .then(res => res.json())
        .then(res => {
            this.eventList = res
            this.showWasteTypes=true
        })
        .catch(err => {
                console.log(err)
        })
      },
      searchPostalPlace(val) {
            if (this.isLoading) return

            this.isLoading = true

            var input = {"addressStr": val,  "sessionId" : this.sessionId, "isPostalAddress": true, "googleLat": this.googleLat, "googleLong": this.googleLong, "googleRange": this.googleRange, "echoAPIUsername": this.echoAPIUsername, "googleAPIKey": this.googleAPIKey}
            // Lazily load input items
            fetch('/api/messages/addressPlaceIdsLookup',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(input)
                }
            )
              .then(res => res.json())
              .then(res => {
                this.postalLookupList = res

              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false))
      },
      searchPlace(val) {
            if (!this.sessionId) {
                this.sessionId = this.uuid;
                console.log("Setting session id to " + this.sessionId)
            }
            this.currentPageNumber = 1
            this.nextYearRemaining = null
            this.currentYearRemaining = null
            this.currentYearRemainingLoose=null
            this.nextYearRemainingLoose=null
            this.allocationSummaryList = null
            this.showWasteTypes=false
            this.eventList = []
            this.isHardwareOnDemandAvailable = null;
            //console.log(val)
            this.selectedStrataNumber = null
            this.isScheduled=false
            //var australiaAddressParser = require("australia-address-parser")
            //console.log(australiaAddressParser.parseLocation(val))

            // Items have already been loaded
            //if (this.pointInfoList.length > 0) return

            // Items have already been requested

            if (this.isLoading) return

            this.isLoading = true

            var input = {"addressStr": val, "sessionId" : this.sessionId, "googleLat": this.googleLat, "googleLong": this.googleLong, "googleRange": this.googleRange, "echoAPIUsername": this.echoAPIUsername, "googleAPIKey": this.googleAPIKey}
            // Lazily load input items
            fetch('/api/messages/addressPlaceIdsLookup',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(input)
                }
            )
              .then(res => res.json())
              .then(res => {
                this.lookupList = res

              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false))

      },
      search (val) {
            //console.log(val)
            this.selectedStrataNumber = null
            //var australiaAddressParser = require("australia-address-parser")
            //console.log(australiaAddressParser.parseLocation(val))

            // Items have already been loaded
            //if (this.pointInfoList.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            var input = {"addressStr": val}
            // Lazily load input items
            fetch('/api/messages/findPoints',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(input)
                }
            )
              .then(res => res.json())
              .then(res => {
                this.pointInfoList = res
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false))
      },
      postalSearch (val) {
            //console.log(val)
            this.selectedStrataNumber = null
            var australiaAddressParser = require("australia-address-parser")
            console.log(australiaAddressParser.parseLocation(val))

            // Items have already been loaded
            if (this.postalPointInfoList.length > 0) return

            // Items have already been requested
            if (this.isPostalLoading) return

            this.isPostalLoading = true

            // Lazily load input items
            fetch('/api/messages/findPoints',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(australiaAddressParser.parseLocation(val))
                }
            )
              .then(res => res.json())
              .then(res => {
                this.postalPointInfoList = res
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isPostalLoading = false))
      },
  },
  mounted() {

    fetch("/api/messages/hello")
      .then((response) => response.text())
      .then((data) => {
        this.msg = data;
      })

  },
  computed: {
    placeIdList(){
        if (!(this.lookupList.lookupList)) {
            return []
        } else {
        //console.log(this.lookupList.lookupList)
        return this.lookupList.lookupList.map(entry => {
                const Description = entry.description.length > this.descriptionLimit
                  ? entry.description.slice(0, this.descriptionLimit) + '...'
                  : entry.description

                return Object.assign({}, entry, { Description })
        })

        }
    },
    postalPlaceIdList() {
        if (!(this.postalLookupList.lookupList)) {
            return []
        } else {
        //console.log(this.postalLookupList.lookupList)
        return this.postalLookupList.lookupList.map(entry => {
                const Description = entry.description.length > this.descriptionLimit
                  ? entry.description.slice(0, this.descriptionLimit) + '...'
                  : entry.description

                return Object.assign({}, entry, { Description })
        })

        }
    },
    addressList() {

        if (!(this.pointInfoList.pointInfoList)) {
            return []
        } else {

        return this.pointInfoList.pointInfoList.map(entry => {
                const Description = entry.description.length > this.descriptionLimit
                  ? entry.description.slice(0, this.descriptionLimit) + '...'
                  : entry.description

                return Object.assign({}, entry, { Description })
        })

        }

    },
    showPostalAddress() {
        if ((this.postalAddressParentObjectId == null) &&
           (this.postalAddressLine1DataTypeId == null) &&
           (this.postalAddressLine2DataTypeId == null) &&
           (this.postalAddressSuburbDataTypeId == null) &&
           (this.postalAddressStateDataTypeId == null) &&
           (this.postalAddressPostCodeDataTypeId == null)) {
            return false
        }
        return true

    },
    postalAddressList() {

        if (!(this.postalPointInfoList.pointInfoList)) {
            return []
        } else {

        return this.postalPointInfoList.pointInfoList.map(entry => {
                const Description = entry.description.length > this.descriptionLimit
                  ? entry.description.slice(0, this.descriptionLimit) + '...'
                  : entry.description

                return Object.assign({}, entry, { Description })
        })

        }
    },
    getNumberOfMattress() {
        var list = this.wasteTypesList;
        for (let i = 0; i < list.length; i++) {
            if ((list[i].id == "2586") ||  (list[i].id == "2887") || (list[i].id == "3049")|| (list[i].id == "3108"))  {
                return list[i].qtyVal
            }
        }
        return ""
    },
    getMaxMattressQty() {
        var list = this.wasteTypesList;
        for (let i = 0; i < list.length; i++) {
            if ((list[i].id == "2586") ||  (list[i].id == "2887") || (list[i].id == "3049")|| (list[i].id == "3108"))  {
                if (list[i].qtyRequired == true) {
                    return list[i].qtyMax
                }
            }
        }
        return 0
    },
    isNoMoreAllocationsRemain:function() {
         var list = this.wasteTypesList;
         var count=0;
         for (let i = 0; i < list.length; i++) {
            var item = list[i];
            if (item.secondDatePicker == true) {
                    count++;
            }
         }

        if (count > 0) {
              return ((this.currentYearRemaining == 0) &&
                     (this.nextYearRemaining == 0) &&
                     (this.currentYearRemainingLoose == 0) &&
                     (this.nextYearRemainingLoose ==0));
        } else {
              return ((this.currentYearRemaining == 0) &&
                     (this.nextYearRemaining == 0));
        }
    },
    sizeDataTypeId: function() {
        return getLabel(this.org, this.labels, "sizeDataTypeId")
    },
    privacyFlagLabel: function() {
        return getLabel(this.org, this.labels, "privacyFlagLabel")
    },
    uuid : function() {
       return uuidv4()
    },
    org : function() {
        return getRequestParam()
    },
    complexAllocations: function() {
        return getLabel(this.org, this.labels, "complexAllocations")
    },
    hasComplexAllocationAvailable: function() {
        var result = false
        if (this.allocationSummaryList && this.allocationSummaryList.length > 0) {
            var list = this.allocationSummaryList

            for (let i = 0; i < list.length; i++) {
                  var item = list[i];
                  if (item.currentYearRemaining > 0) {
                    return true
                  } else if (item.nextYearRemaining > 0) {
                    return true
                  }
            }

        }
        return result
    },
    allocationsThisYear: function() {
        return getLabel(this.org, this.labels, "allocationsThisYear")
    },
    allocationsNextYear: function() {
        return getLabel(this.org, this.labels, "allocationsNextYear")
    },
    allocations: function() {
        return getLabel(this.org, this.labels, "allocations")
    },
    eventTypeId: function() {
        return getLabel(this.org, this.labels, "eventTypeId")
    },
    councilLabel: function() {
        return getLabel(this.org, this.labels, "councilLabel")
    },
    currentYearMessage: function() {
        if (this.complexAllocations == false) {
            return this.allocationsThisYear.text.replace('<currentYearRemaining>', this.currentYearRemaining)
        } else {
            return ""
        }

    },
    displayStickerLetterTickbox: function() {
        return getLabel(this.org, this.labels, "displayStickerLetterTickbox")
    },
    displayStickerLetterTickboxStrata: function() {
        return getLabel(this.org, this.labels, "displayStickerLetterTickboxStrata")
    },
    strataRequireStickerFlagLabel: function() {
        return getLabel(this.org, this.labels, "strataRequireStickerFlagLabel")
    },
    nonStrataRequireStickerFlagLabel: function() {
        return getLabel(this.org, this.labels, "nonStrataRequireStickerFlagLabel")
    },
    stickerLetterDatatypeId: function() {
        return getLabel(this.org, this.labels, "stickerLetterDatatypeId")
    },
    phoneNumberErrorLabel: function() {
        return getLabel(this.org, this.labels, "phoneNumberErrorLabel")
    },
    emailErrorLabel: function() {
        return getLabel(this.org, this.labels, "emailErrorLabel")
    },
    nextYearMessage: function() {
        if (this.complexAllocations == false) {
            return this.allocationsNextYear.text.replace('<nextYearRemaining>', this.nextYearRemaining)
        } else {
            return ""
        }
    },
    strataDataTypeId: function() {
        return getLabel(this.org, this.labels, "strataDataTypeId")
    },
    onDemandServiceId: function() {
        return getLabel(this.org, this.labels, "onDemandServiceId")
    },
    scheduledServiceId: function() {
        return getLabel(this.org, this.labels, "scheduledServiceId")
    },
    echoAPIUsername: function() {
        return getLabel(this.org, this.labels, "echoAPIUsername")
    },
    sizeRequired: function() {
       return getLabel(this.org, this.labels, "sizeRequired")
    },
    googleLat: function() {
       return getLabel(this.org, this.labels, "googleLat")
    },
    googleLong: function() {
        return getLabel(this.org, this.labels, "googleLong")
    },
    googleRange: function() {
        return getLabel(this.org, this.labels, "googleRange")
    },
    googleAPIKey: function() {
        return getLabel(this.org, this.labels, "googleAPIKey")
    },
    monthRange: function() {
        return getLabel(this.org, this.labels, "monthRange")
    },
    monthRangeStrata: function() {
        return getLabel(this.org, this.labels, "monthRangeStrata")
    },
    scheduledPickupErrorMessage: function() {
        var message = getLabel(this.org, this.labels, "scheduledPickupErrorMessage")
        if (message != null) {
            return message.replace("{{this.scheduledDateStr}}", this.scheduledDateStr)
        } else {
            return "";
        }
    },
    noHardwasteOnDemandErrorMsg: function() {
        return getLabel(this.org, this.labels, "noHardwasteOnDemandErrorMsg")
    },
    incorrectCouncilErrorMsg: function() {
        return getLabel(this.org, this.labels, "incorrectCouncilErrorMsg")
    },
    propertyNotFoundErrorMsg: function() {
        return getLabel(this.org, this.labels, "propertyNotFoundErrorMsg")
    },
    eventStates: function() {
        return getLabel(this.org, this.labels, "eventStates")
    },
    addressLookupLabel: function() {
        return getLabel(this.org, this.labels, "addressLookupLabel")
    },
    strataPlanNumberLabel: function() {
        return getLabel(this.org, this.labels, "strataPlanNumberLabel")
    },
    firstDateOfBookingLabel: function() {
        return getLabel(this.org, this.labels, "firstDateOfBookingLabel")
    },
    secondDateOfBookingLabel: function() {
        return getLabel(this.org, this.labels, "secondDateOfBookingLabel")
    },
    firstDatePicker: function() {
         var list = this.wasteTypesList;
         var count=0;
         for (let i = 0; i < list.length; i++) {
            var item = list[i];
            if (this.selectedWasteTypes.includes(item.id)) {
                if (item.secondDatePicker == false) {
                    count++;
                }
            }
         }
         if (count > 0) {
            return true
         }
         return false;

    },
    secondDatePicker: function() {
         var list = this.wasteTypesList;
         var count=0;
         for (let i = 0; i < list.length; i++) {
            var item = list[i];
            if (this.selectedWasteTypes.includes(item.id)) {
                if (item.secondDatePicker == true) {
                    count++;
                }
           }
         }
         if (count > 0) {
            return true
         }
         return false;
    },
    firstNameLabel: function() {
        return getLabel(this.org, this.labels, "firstNameLabel")
    },
    surnameLabel: function() {
        return getLabel(this.org, this.labels, "surnameLabel")
    },
    strataManagerLabel : function() {
        return getLabel(this.org, this.labels, "strataManagerLabel")
    },
    strataNumberErrorMsg : function() {
        return getLabel(this.org, this.labels, "strataNumberErrorMsg")
    },
    strataAuthorisationFlagLabel : function() {
        return getLabel(this.org, this.labels, "strataAuthorisationFlagLabel")
    },
    postalAddressFlagLabel : function() {
        return getLabel(this.org, this.labels, "postalAddressFlagLabel")

    },
    postalAddressPOBoxFlagLabel : function() {
        return getLabel(this.org, this.labels, "postalAddressPOBoxFlagLabel")
    },
    postalAddressLookupLabel: function() {
        return getLabel(this.org, this.labels, "postalAddressLookupLabel")
    },
    phoneNumberLabel: function() {
        return getLabel(this.org, this.labels, "phoneNumberLabel")
    },
    emailLabel: function() {
        return getLabel(this.org, this.labels, "emailLabel")
    },
    wasteTypesLabel: function() {
        return getLabel(this.org, this.labels, "wasteTypesLabel")
    },
    sizeLabel: function() {
        return getLabel(this.org, this.labels, "sizeLabel")
    },
    nameDataTypeId: function() {
        return getLabel(this.org, this.labels, "nameDataTypeId")
    },
    postalAddressParentObjectId: function() {
        return getLabel(this.org, this.labels, "postalAddressParentObjectId")
    },
    postalAddressLine1DataTypeId: function() {
        return getLabel(this.org, this.labels, "postalAddressLine1DataTypeId")
    },
    postalAddressLine2DataTypeId: function() {
        return getLabel(this.org, this.labels, "postalAddressLine2DataTypeId")
    },
    postalAddressSuburbDataTypeId: function() {
        return getLabel(this.org, this.labels, "postalAddressSuburbDataTypeId")
    },
    postalAddressStateDataTypeId: function() {
        return getLabel(this.org, this.labels, "postalAddressStateDataTypeId")
    },
    postalAddressPostCodeDataTypeId: function() {
        return getLabel(this.org, this.labels, "postalAddressPostCodeDataTypeId")
    },
    phoneNumberDataTypeId: function() {
        return getLabel(this.org, this.labels, "phoneNumberDataTypeId")
    },
    emailAddressDataTypeId: function() {
        return getLabel(this.org, this.labels, "emailAddressDataTypeId")
    },
    driversNotesDataTypeId: function() {
        return getLabel(this.org, this.labels, "driversNotesDataTypeId")
    },
    raisedByDataTypeId: function() {
        return getLabel(this.org, this.labels, "raisedByDataTypeId")
    },
    raisedBy: function() {
        return getLabel(this.org, this.labels, "raisedBy")
    },
    wasteTypesList: function() {
        return getLabel(this.org, this.labels, "wasteTypes")
    },
    wasteTypesIdList: function() {
         var list = this.wasteTypesList;
         var result = [];
         for (let i = 0; i < list.length; i++) {
            var item = list[i];
            result.push(item.id)
         }
         return result;
    },
    sizeLabelList: function() {
        var list = this.sizeList;
        var result = [];
        if (list != null) {
            for (let i=0; i < list.length; i++) {
                var item = list[i]
                result.push(item.name)
            }
        }
        return result
    },
    serviceDenialErrorMessage: function() {
        return getLabel(this.org, this.labels, "serviceDenialErrorMessage")
    },
    isServiceDenialFlagPresent: function() {
        if ((this.allocationSummaryList!=null) && (this.allocationSummaryList.length > 0)) {
            for (let i=0; i < this.allocationSummaryList.length; i++) {
                var allocation = this.allocationSummaryList[i];
                console.log("Allocation Service denial = " + allocation.isServiceDenial)
                console.log("Allocation name = " + allocation.allocationName)
                if (allocation.isServiceDenial == true) {
                    return true
                }
            }
        }
        return false
    },
    isOnlyAdditionalOnlySelected() {
        var list = this.wasteTypesList;
        var result = true
        for (let i = 0; i < this.selectedWasteTypes.length; i++) {
                var item = this.selectedWasteTypes[i];
                for (let j=0; j < list.length; j++) {
                    var wasteType = list[j];
                    if ((wasteType.id == item) && (wasteType.additionalOnly != true)) {
                        return false
                    }
                }
        }
        return result
    },
    getComplexAllocationList() {
        var arr = [];
        if (this.complexAllocations) {
            var list = this.wasteTypesList
            for (let i=0; i < list.length; i++) {
                var defaultAllocationId = list[i].defaultAllocationId
                var serviceDenialDatatypeId = list[i].serviceDenialDatatypeId
                var allocations = list[i].allocations
                for (let j=0; j < allocations.length; j ++) {
                    let item = allocations[j]
                    item.idDefaultAllocationId = defaultAllocationId
                    item.idServiceDenialDatatypeId = serviceDenialDatatypeId
                    arr.push(item)
                }
            }

            var clean = arr.filter((arr, index, self) =>
                index === self.findIndex((t) => (t.idParent === arr.idParent && t.idYear === arr.idYear && t.idUsed === arr.idUsed && t.idAllowed === arr.idAllowed && t.idDefaultAllocationId === arr.idDefaultAllocationId)))
            return clean;
        } else {
            return null
        }
    },
    usingFinancialYear:function() {
        return getLabel(this.org, this.labels, "usingFinancialYear")
    },
    enableCancel: function() {
        return getLabel(this.org, this.labels, "enableCancel")
    },
    cancellationLockoutPeriod:function() {
        return getLabel(this.org, this.labels, "cancellationLockoutPeriod")
    },
    cancelBookingTitle: function () {
        return getLabel(this.org, this.labels, "cancelBookingTitle")
    },
    cancelBookingMessage: function(){
        return getLabel(this.org, this.labels, "cancelBookingMessage")
    },
    defaultFallbackTitle: function() {
        return getLabel(this.org, this.labels, "defaultFallbackTitle")
    },
    dateOffset:function() {
        return getLabel(this.org, this.labels, "dateOffset")
    },
    sizeList: function() {
        return getLabel(this.org, this.labels, "sizeTypes")
    },
    numberOfMattressesLabel: function() {
        return getLabel(this.org, this.labels, "numberOfMattressesLabel")
    },
    collectionInstructionsLabel: function() {
        return getLabel(this.org, this.labels, "collectionInstructionsLabel")
    },
    termsConditionsFlagLabel: function() {
        return getLabel(this.org, this.labels, "termsConditionsFlagLabel")
    },
    termsConditionsContent: function() {
        return getLabel(this.org, this.labels, "termsConditionsContent")
    },
    submitConfirmationContentTop: function() {
        var template = getLabel(this.org, this.labels, "submitConfirmationContentTop")
        let valueMap = {
                    getConfirmationNumber: this.getConfirmationNumber,
                    selectedDate: this.selectedDate,
                    selectedAddressString: this.selectedAddressString,
                    getSelectedSize: this.getSelectedSize,
                    selectedDate2: this.selectedDate2
        };
        let allKeys = Object.keys(valueMap);
        allKeys.forEach((key) => {
                var myRegExp = new RegExp('{{' + key + '}}','i');
                template = template.replace(myRegExp, valueMap[key]);
        });
        return template
    },
    submitConfirmationContentMiddle: function() {
        var template = getLabel(this.org, this.labels, "submitConfirmationContentMiddle");
        let valueMap = {
            getConfirmationNumber: this.getConfirmationNumber,
            selectedDate: this.selectedDate,
            selectedAddressString: this.selectedAddressString,
            getSelectedSize: this.getSelectedSize,
            selectedDate2: this.selectedDate2
        };
        let allKeys = Object.keys(valueMap);
        allKeys.forEach((key) => {
                var myRegExp = new RegExp('{{' + key + '}}','i');
                template = template.replace(myRegExp, valueMap[key]);
        });
        return template
    },
    submitConfirmationContentBottom: function() {
        var template = getLabel(this.org, this.labels, "submitConfirmationContentBottom")
        let valueMap = {
            getConfirmationNumber: this.getConfirmationNumber,
            selectedDate: this.selectedDate,
            selectedAddressString: this.selectedAddressString,
            getSelectedSize: this.getSelectedSize,
            selectedDate2: this.selectedDate2
        };
        let allKeys = Object.keys(valueMap);
        allKeys.forEach((key) => {
                var myRegExp = new RegExp('{{' + key + '}}','i');
                template = template.replace(myRegExp, valueMap[key]);
        });
        return template
    },
    privacyLabel: function() {
        return getLabel(this.org, this.labels, "privacyLabel")
    },
    bookingHistoryLabel: function() {
        return getLabel(this.org, this.labels, "bookingHistoryLabel")
    },
    bookingHeaderRemainingLabel: function() {
      return getLabel(this.org, this.labels, "bookingHeaderCurrentRemainingLabel")
    },
    bookingHeaderNextLabel: function() {
      return getLabel(this.org, this.labels, "bookingHeaderNextRemainingLabel")
    },
    portalTitleLabel: function() {
        return getLabel(this.org, this.labels, "portalTitle")
    },
    logo1: function() {
        return getLabel(this.org, this.labels, "logo1")
    },
    logo2: function() {
        return getLabel(this.org, this.labels, "logo2")
    },
    wasteTypeSelectAnotherErrorMsg: function() {
        return getLabel(this.org, this.labels, "wasteTypeSelectAnotherErrorMsg")
    },
    invalidCollectionsErrorMsg: function() {
        return getLabel(this.org, this.labels, "invalidCollectionsErrorMsg")
    },
    sizeImg: function() {
        return getLabel(this.org, this.labels, "sizeImg")
    },
    availableSlotsList: function() {
        if (this.availableSlots?.availableSlotsForBooking.length > 0) {
            return this.availableSlots.availableSlotsForBooking
        }
        return []
    },
    secondAvailableSlotsList: function() {
        if (this.secondAvailableSlots?.availableSlotsForBooking.length > 0) {
            return this.secondAvailableSlots.availableSlotsForBooking
        }
        return []
    },
    availableDatesLabel: function() {
        return getLabel(this.org, this.labels, "availableDatesLabel")
    },
    notAvailableDatesLabel : function () {
        return getLabel(this.org, this.labels, "notAvailableDatesLabel")
    },
    welcomeTextLabel:function() {
        return getLabel(this.org, this.labels, "welcomeTextLabel")
    },
    anotherBookingLabel:function() {
        return getLabel(this.org, this.labels, "anotherBookingLabel")
    },
    sameDayBookingFlag: function() {
      return getLabel(this.org, this.labels, "sameDayBooking")
    },
    showBookingHistoryLabel: function() {
        if (this.eventList.length > 0) {
            return true;
        }
        return false;
    },
    getSelectedWasteTypesUniqueIds: function() {
                var result=[]
                for (let i=0; i < this.selectedWasteTypes.length; i++) {
                    var id = this.selectedWasteTypes[i];
                    for (let j=0; j < this.wasteTypesList.length; j++) {
                        var wasteType = this.wasteTypesList[j];
                        if (wasteType.id == id) {
                            result.push(wasteType.exclusiveID)
                            break;
                        }
                    }
                }
                return result;
    },
    isQtyValid: function () {

        console.log("isOnlyAdditionalOnlySelected=" + this.isOnlyAdditionalOnlySelected)
        if ((this.selectedWasteTypes.length > 1)) {
             if (!((this.selectedWasteTypes.includes("2586"))||
                   (this.selectedWasteTypes.includes("2887"))||
                   (this.selectedWasteTypes.includes("3049"))||
                   (this.selectedWasteTypes.includes("3108")))) {
                return true;
             } else {
                 var res = false
                 for (let i=0; i < this.selectedWasteTypes.length; i++) {
                    if ((this.selectedWasteTypes.includes("2586"))||
                        (this.selectedWasteTypes.includes("2887"))||
                        (this.selectedWasteTypes.includes("3049"))||
                        (this.selectedWasteTypes.includes("3108"))) {
                        var id = this.selectedWasteTypes[i];
                        for (let j=0; j < this.wasteTypesList.length; j++) {
                            var wasteType = this.wasteTypesList[j];
                            if (wasteType.id == id) {
                                var inputQty = this.getNumberOfMattress
                                var maxQty = wasteType.qtyMax
                                if ((inputQty > 0) && (inputQty <= maxQty)) {
                                    res = true
                                    return res
                                }
                            }
                        }
                    }
                 }
                 return res;
            }
        } else if ((this.selectedWasteTypes.length == 1) && (!this.isOnlyAdditionalOnlySelected)) {
            return true
        } else {
            return false
        }
    },
    validateEventId: function() {
        var result = false
        if (this.cancelEventId) {
            if (this.eventList) {
                if(this.eventList.length > 0){
                    for (let i=0; i < this.eventList.length; i++) {
                        if ((this.cancelEventId == this.eventList[i].eventId) && (this.eventList[i].status == "Allocated to Crew")) {
                            result = true
                            break
                        }
                    }
                }
            }
        }
        return result
    },
    validatePostalAddress: function() {
        if (
                (this.postalAddressFlag)&&
                (!this.postalAddressPOBoxFlag)) {
            if (!this.selectedPostalAddress) {

                if ((this.postalLookupList != null) && (this.postalLookupList.lookupList != null)) {
                    return ['This address is not valid']
                } else {
                    return ['This field is required']
                }
            }
        }
        return true
    },
    validateWasteTypes:function() {
        if (this.selectedWasteTypes.length == 0) {
            var emptyErrorMsg = getLabel(this.org, this.labels, "wasteTypeEmptyErrorMsg")
            return [emptyErrorMsg]
        } else {
            var errorMsg = getLabel(this.org, this.labels, "wasteTypeSelectAnotherErrorMsg")
            if ((this.selectedWasteTypes.length == 1)) {
                var id = this.selectedWasteTypes[0]
                for (let i=0; i < this.wasteTypesList.length; i++) {
                    var wasteType = this.wasteTypesList[i]
                    if ((id == wasteType.id) && (wasteType.additionalOnly == true)){
                        return [errorMsg]
                    }
                }

            }
        }
        return true
    },
    showQtyNumberField: function() {
        if ((this.selectedWasteTypes.includes("2586")) || (this.selectedWasteTypes.includes("2887"))||
            (this.selectedWasteTypes.includes("3049")) || (this.selectedWasteTypes.includes("3108"))) {
            return true
        }
        return false
    },
    showConfirmation: function() {
        if (this.postResponse != null) {
            if ("success" == this.postResponse.status) {
                return true
            }
        }
        return false
    },
    showSubmit: function() {
        if (this.currentPageNumber && this.currentPageNumber < 2) {
            return false
        }
        if (this.showQtyNumberField && (!(/^[1-9][0-9]?$|^this.getMaxMattressQty$/.test(this.getNumberOfMattress)))) {
            return false
        }
        if ((!this.selectedSlot)&&(!this.secondSelectedSlot)) {
            return false
        }
        if (this.isScheduled) {
            return false
        }
        if ((this.isHardwareOnDemandAvailable == false)) {
            return false
        }
        if (!this.selectedStrataNumber) {
            if (!this.firstName) {
                return false
            }
            if (!this.surname) {
                return false
            }
        } else {
            if (!this.strataManager) {
                return false
            }
        }

        if (!this.strataAuthorisationFlag) {
            return false
        }
        if ((!this.phoneNumber)) {
            return false
        }
        if (!(this.phoneNumber && this.phoneNumber.length != 8)) {
            return false
        }
        if (!((/^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/.test(this.phoneNumber)))) {
            return false
        }
        if ((!this.email) ||(!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]*@[a-zA-Z0-9-]*(\.\w{2,})*$/.test(this.email)))) {
            return false
        }
        if ((this.postalAddressFlag)) {
            if ((this.postalAddressPOBoxFlag) ) {
                if (!this.postalAddressPOBox) {
                    return false;
                }
            } else if ((!this.postalAddressPOBoxFlag) && (!this.selectedPostalAddress)) {
                return false
            }
        }
        if (!this.termsConditionsFlag) {
            return false
        }
        if (!this.privacyFlag) {
            return false
        }

        if (this.currentPageNumber != 7) {
            return false
        }

        return true;
    },
    selectedDate: function() {
        if ((this.selectedSlot != null) && (this.availableSlotsList != null)) {
            for (let i=0; i < this.availableSlotsList.length; i++){
                if (this.availableSlotsList[i].referenceNumberList == this.selectedSlot) {
                    return this.availableSlotsList[i].endDate
                }
            }
        }
        return null;
    },
    selectedDate2: function() {
        if ((this.secondSelectedSlot != null) && (this.secondAvailableSlotsList != null)) {
            for (let i=0; i < this.secondAvailableSlotsList.length; i++){
                if (this.secondAvailableSlotsList[i].referenceNumberList == this.secondSelectedSlot) {
                    return this.secondAvailableSlotsList[i].endDate
                }
            }
        }
        return null;
    },
    getConfirmationNumber: function() {
        if ((this.postResponse != null) && (this.postResponse.confirmationNumber!=null)) {
            return this.postResponse.confirmationNumber
        } else {
            return ""
        }
    },
    getSelectedSize: function() {
        if (this.selectedSize == null) {
            return ""
        }
        if (this.selectedSize == 1) {
            return this.sizeList[0].name;
        } else {
            return this.sizeList[1].name;
        }
    },
    selectedAddressString: function() {
        if (this.selectedAddress != null) {
            return this.selectedAddress.description
        }
        return ""
    },
    form () {
            return {
              name: this.name,
              address: this.address,
              city: this.city,
              state: this.state,
              zip: this.zip,
              country: this.country,
            }
    }
  },
  methods: {
      showCancel: function(date) {
          const cancelDate = Date.parse(date)

          //console.log("Cancel Date =" + cancelDate)
          var offset = this.cancellationLockoutPeriod
          //console.log("offset=" + offset)

          var cutOffDate = new Date()

          if (offset != null) {
            cutOffDate.setDate(cutOffDate.getDate() + offset)
          }
          //console.log("CutoffDate = " + cutOffDate)
          if (cutOffDate >= cancelDate) {
            return false
          } else {
            return true
          }
      },
      addFiles() {
            console.log('files', this.files)
            let reader = new FileReader();
            reader.onloadend = (e) => {

                                console.log(e)
                                let fileData = reader.result
                                let result = JSON.parse(fileData); // Parse the result into an object
                                this.labels = result
            }
            reader.readAsText(this.files);
      },
      orderAllocationSummaryList: function(allocationSummaryList) {
        console.log("OrderAllocationSummaryList")
        console.log(allocationSummaryList)
        console.log("--------")
        if (this.complexAllocations == false) {
            return allocationSummaryList
        }
        var result = [];
        for(let i=0; i < this.wasteTypesList.length;i++) {
            var wasteType = this.wasteTypesList[i];
            for(let j=0; j < wasteType.allocations.length; j++) {
                var allocation = wasteType.allocations[j];
                var found = false
                for (let k=0; k < allocationSummaryList.length; k++ ) {
                    var originAllocation = allocationSummaryList[k];
                    if (allocation.idParent == originAllocation.allocationId) {
                        result.push(originAllocation)
                        found = true
                    }
                }
                console.log("match not found="+originAllocation.allocationId)
                //Match is not found check the id is of default allocation
                if (!found) {
                    console.log("waste default Alloc id from config=" + wasteType.defaultAllocationId)
                    for (let l=0; l < allocationSummaryList.length; l++ ) {
                        originAllocation = allocationSummaryList[l];
                        if (wasteType.defaultAllocationId == originAllocation.allocationId) {
                            var defaultAlloc = {}
                            defaultAlloc.isCurrentYearPickupAllowed = originAllocation.isCurrentYearPickupAllowed
                            defaultAlloc.isNextYearPickupAllowed = originAllocation.isNextYearPickupAllowed
                            defaultAlloc.currentYearRemaining = originAllocation.currentYearRemaining
                            defaultAlloc.nextYearRemaining = originAllocation.nextYearRemaining
                            defaultAlloc.allocationId = originAllocation.allocationId
                            defaultAlloc.allocationName = allocation.title
                            defaultAlloc.isServiceDenial = originAllocation.isServiceDenial
                            result.push(defaultAlloc)
                        }
                    }
                }
            }
        }

        //remove duplicate
        var uniqSet = []
        var uniqueNames = []
        for (let m=0; m <  result.length; m++) {
            var alloc= result[m];
            var name = alloc.allocationName
            if (!uniqueNames.includes(name)) {
                uniqueNames.push(name)
                uniqSet.push(alloc)
            }
        }

        if (uniqSet.length == 0) {
            //in thee case where default values doesn't match waste type summary, we return the list back
            return allocationSummaryList
        }
        return uniqSet;
      },
      isLooseItem: function(parentId) {
        for (let i=0; i < this.wasteTypesList.length;i++) {
            var wasteType = this.wasteTypesList[i];
            for(let j=0; j < wasteType.allocations.length; j++) {
                var allocation = wasteType.allocations[j];
                if (allocation.idParent == parentId) {
                    if (wasteType.secondDatePicker) {
                        return true
                    }
                }
            }
            if (parentId == wasteType.defaultAllocationId) {
                if (wasteType.secondDatePicker) {
                    return true
                }
            }
        }
        return false
      },
      isWasteTypeItemDisabled: function(val, groupName) {
          if (this.disableFlag == true) {
              return true
          }
          if (this.isServiceDenialFlagPresent) {
            return true
          }

          if (this.selectedWasteTypes.length > 0) {
              var groupList = this.getSelectedWasteTypesUniqueIds
              if ((!this.selectedWasteTypes.includes(val)) && (groupList.includes(groupName))) {
                return true
              }
          }
          return false
      },
      clearCacheItems() {
        this.$refs.searchPlaceAutoComplete.cacheItems = [];
        this.$refs.el.lazySearch = ''
      },
      addressLookup: function() {
            this.selectedStrataNumber = null
            if (!this.addressSearch) {
                this.predictionList = []
                this.selectedAddress = null
                this.clearCacheItems()
                return
            }

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true
            var input = {"addressStr": this.addressSearch,  "googleLat": this.googleLat, "googleLong": this.googleLong, "googleRange": this.googleRange, "echoAPIUsername": this.echoAPIUsername, "googleAPIKey": this.googleAPIKey}
            alert("$$$$" + JSON.stringify(input))
            // Lazily load input items
            fetch('/api/messages/addressLookup',
                {
                    method:'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(input)
                }
            )
              .then(res => res.json())
              .then(res => {
                this.predictionList = res
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false))
      },
      setPageNumber: function() {
          //console.log(this.currentPageNumber)
          if ((this.currentPageNumber > 2) &&
              ((this.selectedWasteTypes.length == 0)||
              ((this.selectedWasteTypes.length == 1) &&
               ((this.selectedWasteTypes.includes("2586"))||
                (this.selectedWasteTypes.includes("2887"))||
                (this.selectedWasteTypes.includes("3049"))||
                (this.selectedWasteTypes.includes("3108"))) &&
               (this.isOnlyAdditionalOnlySelected == true)
              ))) {
            this.currentPageNumber = 3
          } else {

            if (this.currentPageNumber == 2) {
              if ((this.strataNumber != null) && (this.strataNumber == this.selectedStrataNumber)) {
                    this.currentPageNumber ++;
              }
            } else if (this.currentPageNumber == 3) {
                if (!((this.selectedWasteTypes.length == 0)||
                     ((this.selectedWasteTypes.length == 1) &&
                      ((this.selectedWasteTypes.includes("2586"))||
                       (this.selectedWasteTypes.includes("2887"))||
                       (this.selectedWasteTypes.includes("3049"))||
                       (this.selectedWasteTypes.includes("3108"))) &&
                      (this.isOnlyAdditionalOnlySelected == true)))) {
                    this.currentPageNumber ++;
                }
            } else if (this.currentPageNumber == 4) {
               if (this.sizeRequired) {
                //If mattress is selected we need to make sure number of mattress are put in before showing next page
                if (((this.selectedWasteTypes.includes("2586"))||
                      (this.selectedWasteTypes.includes("2887"))||
                      (this.selectedWasteTypes.includes("3049"))||
                      (this.selectedWasteTypes.includes("3108")))) {

                      for (let i=0; i < this.selectedWasteTypes.length; i++) {
                            if ((this.selectedWasteTypes.includes("2586"))||
                                (this.selectedWasteTypes.includes("2887"))||
                                (this.selectedWasteTypes.includes("3049"))||
                                (this.selectedWasteTypes.includes("3108"))) {
                                var id = this.selectedWasteTypes[i];
                                for (let j=0; j < this.wasteTypesList.length; j++) {
                                    var wasteType = this.wasteTypesList[j];
                                    if (wasteType.id == id) {
                                        var inputQty = this.getNumberOfMattress
                                        var maxQty = wasteType.qtyMax
                                        if (((inputQty > 0) && (inputQty <= maxQty)) && (this.selectedSize != null)) {
                                              this.currentPageNumber ++;
                                        }
                                    }
                                }
                            }
                      }

                } else {
                    //console.log("isShowNotEnoughCollectionsError=" +this.isShowNotEnoughCollectionsError)
                    //var result = isCollectionRequestedTooLarge(this.selectedSize, this.selectedMonth, this.currentYearRemaining, this.nextYearRemaining, this.allocationSummaryList, this.usingFinancialYear)
                    //console.log("isCollectionRequestedTooLarge=" + result)

                    if ((this.selectedSize != null) && (this.isShowNotEnoughCollectionsError!=true)) {
                        this.currentPageNumber ++;
                    } else if ((this.isShowNotEnoughCollectionsError == true) &&
                               (!isCollectionRequestedTooLarge(this.selectedSize, this.selectedMonth, this.currentYearRemaining, this.nextYearRemaining, this.allocationSummaryList, this.usingFinancialYear)) &&
                               (!isCollectionRequestedTooLarge(this.selectedSize, this.selectedSecondMonth, this.currentYearRemaining, this.nextYearRemaining, this.allocationSummaryList, this.usingFinancialYear))) {
                        this.isShowNotEnoughCollectionsError = null
                        this.currentPageNumber ++;
                    }
                }
               } else {
                    this.currentPageNumber ++;
               }
            } else if (this.currentPageNumber == 5) {
               //console.log ("Selected slot=" + (this.selectedSlot == null))
               //console.log("selectedslot =" + this.selectedSlot)
               //console.log("secondSelectedslot =" + this.secondSelectedSlot)
               if (!this.secondDatePicker) {
                    if (this.selectedSlot != null) {
                        this.currentPageNumber ++;
                    }
               } else {
                    if ((this.selectedSlot !=null) || (this.secondSelectedSlot != null)) {
                        this.currentPageNumber ++;
                    }
               }
            } else if (this.currentPageNumber == 6) {
                if (this.strataAuthorisationFlag) {
                    this.currentPageNumber ++;
                }
            }
          }
      },
      getAllocationsBasedOnSelectedWasteTypes: function(isSecondDatePicker) {
        var result=[]
        var allocationIds = []
        for (let i=0; i <  this.selectedWasteTypes.length; i++) {
            var selectedWasteTypeId = this.selectedWasteTypes[i]
            for (let j=0; j < this.wasteTypesList.length;j++) {
                var wasteType = this.wasteTypesList[j];
                if (wasteType.secondDatePicker == isSecondDatePicker) {
                    if ((selectedWasteTypeId == wasteType.id) && (wasteType.allocations != null)) {
                        for (let k = 0; k < wasteType.allocations.length; k++) {
                            var allocation = wasteType.allocations[k]
                            allocationIds.push(allocation.idParent);
                        }
                        //allocationIds.push(wasteType.defaultAllocationId)
                    }
                }
            }
        }
        if ((this.complexAllocations) && (this.allocationSummaryList != null) && (this.allocationSummaryList.length > 0)){
            for (let j=0; j< allocationIds.length;j++) {
                var alloc = allocationIds[j];
                for (let k=0; k< this.allocationSummaryList.length; k++) {
                    var allocationSummary = this.allocationSummaryList[k];
                    if (alloc == allocationSummary.allocationId) {
                        result.push(allocationSummary)
                    }
                }
            }
            return result;
        }
        return result
      }
      ,
      allowedMonthsSkip: function(val) {
        //First date picker
        return this.allowedMonths(val, false)
      },
      allowedMonthsLoose: function(val) {
        //Second date picker
        return this.allowedMonths(val, true)
      },
      allowedMonths: function(val, isLooseItem) {
        var foundAllocations = this.getAllocationsBasedOnSelectedWasteTypes(isLooseItem)
        var monthsInAdvance = this.monthRange
        if ((this.selectedStrataNumber) && (this.monthRangeStrata != null)){
                    monthsInAdvance = this.monthRangeStrata
        }
        var usingFinancialYear = this.usingFinancialYear
        var startDate = new Date()
        if (!usingFinancialYear) {
            //Using Calendar year
            var currentYear = startDate.getFullYear()
            startDate.setDate(startDate.getDate()+this.dateOffset)
            var startDateYear = startDate.getFullYear()
            //console.log("startDate=" + startDate.getFullYear()+"/"+(startDate.getMonth()+1)+"/"+startDate.getDate())
            var endDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
            endDate.setMonth(endDate.getMonth() + monthsInAdvance)
            endDate.setDate(endDate.getDate() - 1)
            //console.log("endDate=" + endDate.getFullYear()+"/"+(endDate.getMonth()+1)+"/"+endDate.getDate())

            var selectedYear = parseInt(val.split('-')[0], 10)
            var selectedMonth = parseInt(val.split('-')[1], 10)
            //Find next month subtract by 1 day to get the end of the current month, and ths date is used as selectedDate
            var selectedDate = new Date(selectedYear, selectedMonth, 1)
            selectedDate.setDate(selectedDate.getDate()-1);

            var isCurrentYear;
            var isNextYear;
            if (currentYear ==  startDateYear) {
                isCurrentYear = ((selectedYear - startDate.getFullYear() ) == 0)
                isNextYear = ((selectedYear - startDate.getFullYear() ) == 1)
            } else if (startDateYear > currentYear) {
                isCurrentYear = ((selectedYear - currentYear ) == 0)
                isNextYear = ((selectedYear - currentYear ) == 1)
            }

            if (selectedDate>=startDate && selectedDate <= endDate) {
                if ((isCurrentYear) && (this.isCurrentYearAvailableForBooking)) {
                    if (foundAllocations != null) {
                        for (let i=0; i <foundAllocations.length; i++) {
                            var alloc = foundAllocations[i];
                            if (alloc.currentYearRemaining <= 0) {
                                return false
                            }
                        }
                        return true
                    }
                } else if ((isNextYear) && (this.isNextYearAvailableForBooking)) {
                    if (foundAllocations != null) {
                        for (let i=0; i <foundAllocations.length; i++) {
                            alloc = foundAllocations[i];
                            if (alloc.nextYearRemaining <= 0) {
                                return false
                            }
                        }
                        return true
                    }
                }
            }
        } else {
        //Calculating start date and end date using Financial year

            currentYear = startDate.getFullYear()
            startDate.setDate(startDate.getDate()+this.dateOffset)
            startDateYear = startDate.getFullYear()
            currentYear = startDateYear

            //console.log("startDate=" + startDate.getFullYear()+"/"+(startDate.getMonth()+1)+"/"+startDate.getDate())
            endDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
            endDate.setMonth(endDate.getMonth() + monthsInAdvance)
            endDate.setDate(endDate.getDate() - 1)


            //console.log("endDate=" + endDate.getFullYear()+"/"+(endDate.getMonth()+1)+"/"+endDate.getDate())

            selectedYear = parseInt(val.split('-')[0], 10)
            selectedMonth = parseInt(val.split('-')[1], 10)
            //Find next month subtract by 1 day to get the end of the current month, and ths date is used as selectedDate
            selectedDate = new Date(selectedYear, selectedMonth, 1)
            selectedDate.setDate(selectedDate.getDate()-1);

            var isCurrentFinancialYear = false;
            var isNextFinancialYear = false;
            //console.log("selectedYear=" + selectedYear)
            //console.log("selectedMonth=" + selectedMonth)

            var currentMonth = startDate.getMonth()
            currentMonth++;

            if ((selectedYear == currentYear)) {
                if ((selectedMonth >= 1) && (selectedMonth <=6 )) {
                    if ((currentMonth >= 1) &&(currentMonth <= 6)){
                        isCurrentFinancialYear = true
                    }
                } else if ((selectedMonth > 6) && (selectedMonth <= 12)) {
                    if ((currentMonth > 6) &&(currentMonth <= 12)){
                        isCurrentFinancialYear = true
                    } else {
                        isNextFinancialYear = true
                    }
                }
            } else if ((selectedYear - startDate.getFullYear()) == 1) {
                if ((selectedMonth >= 1) && (selectedMonth <=6 )) {
                    if (currentMonth > 6 && currentMonth <=12) {
                        isCurrentFinancialYear = true
                    }
                } else {
                    if (currentMonth > 6 && currentMonth <=12) {
                        isNextFinancialYear = true
                    }
                }
            }

            //console.log("isCurrentFinancialYear=" + isCurrentFinancialYear)
            //console.log("isNextFinancialYear = " + isNextFinancialYear)
            if (selectedDate>=startDate && selectedDate <= endDate) {
                if ((isCurrentFinancialYear) && (this.isCurrentYearAvailableForBooking)) {
                    if (foundAllocations != null) {
                        for (let i=0; i <foundAllocations.length; i++) {
                            alloc = foundAllocations[i];
                            if (alloc.currentYearRemaining <= 0) {
                                return false
                            }
                        }
                        return true
                    }
                } else if ((isNextFinancialYear) && (this.isNextYearAvailableForBooking)) {
                    if (foundAllocations != null) {
                        for (let i=0; i <foundAllocations.length; i++) {
                            alloc = foundAllocations[i];
                            if (alloc.nextYearRemaining <= 0) {
                                return false
                            }
                        }
                        return true
                    }
                }
            }
        }
        return false
      },
      addressCheck () {
        this.errorMessages = this.address && !this.name
          ? `Hey! I'm required`
          : ''

        return true
      },
      resetSearch: function() {
        window.location.reload(true)
      },
      getWasteTypesBasedOnDatePicker: function(selectedWasteTypeList, datePicker) {
        var result = [];
        var secondDatePicker = false;
        if (datePicker == 2) {
            secondDatePicker = true;
        }
        for (let i=0; i < selectedWasteTypeList.length; i++) {
            var selectedWasteTypeId = selectedWasteTypeList[i];
            for (let j=0; j < this.wasteTypesList.length;j++) {
                var wasteType = this.wasteTypesList[j];
                if ((selectedWasteTypeId == wasteType.id) && ( wasteType.secondDatePicker == secondDatePicker)) {
                    result.push(wasteType.id);
                }
            }
        }
        return result;
      },
      searchForSlots: function(selectedMonth, datePicker) {
        console.log("in searchForSlots")
        this.disableFlag=true
        if (isCollectionRequestedTooLarge(this.selectedSize, selectedMonth, this.currentYearRemaining, this.nextYearRemaining, this.allocationSummaryList, this.usingFinancialYear)) {
            console.log("return error")
            console.log("@@@@@@")
            this.isShowNotEnoughCollectionsError = true;
            this.currentPageNumber = 4;
            return;
        }

        //Actual search begins
        if (!this.secondDatePicker) {
            this.selectedSlot = null
        } else {
            if (this.selectedSlot != null) {
                this.secondSelectedSlot = null;
            }
        }
        var payload = {}
        this.isLoading = true
        payload.wasteTypes = this.getWasteTypesBasedOnDatePicker(this.selectedWasteTypes, datePicker)
        //payload.quantityList = this.wasteTypesList
        payload.pointAddress = this.selectedAddress.sharedRef
        payload.selectedMonth = selectedMonth
        payload.dateOffset = this.dateOffset
        payload.echoAPIUsername = this.echoAPIUsername
        payload.eventTypeId = this.eventTypeId
        payload.onDemandServiceId = this.onDemandServiceId
        console.log(JSON.stringify(payload))
        var request = JSON.stringify(payload)
        fetch('/api/messages/getAvailableSlotsForBooking',
                        {
                            method:'post',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/json'
                            },
                            body: request
                        }
        )
        .then(res => res.json())
        .then(res => {
             if (datePicker == 1) {
                this.availableSlots = res
                if (this.availableSlots?.availableSlotsForBooking.length > 0) {
                    if (!this.secondDatePicker) {
                        this.disableFlag=true
                    }
                    this.reservationGuid = res.guid
                }
             } else {
                this.secondAvailableSlots = res
                if (this.secondAvailableSlots?.availableSlotsForBooking.length > 0) {
                    this.disableFlag=true
                    this.reservationGuid = res.guid
                }
             }
        })
        .catch(err => {
                        console.log(err)
        })
        .finally(() => (this.isLoading = false))
      },
      resetForm () {
        this.errorMessages = []
        this.formHasErrors = false

        Object.keys(this.form).forEach(f => {
          this.$refs[f].reset()
        })
      },
      cancel() {
        if (this.validateEventId) {
            console.log(JSON.stringify(this.eventList))
            this.isLoading = true
            console.log("Cancel event Id = " + this.cancelEventId)
            var payload = {}
            payload.eventId = this.cancelEventId
            payload.echoAPIUsername = this.echoAPIUsername
            console.log("Cancel Event Payload = " + JSON.stringify(payload))
            var request = JSON.stringify(payload)
            fetch('/api/messages/cancelEvent',
                {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: request
                }
            )
            .then(res => res.json())
            .then(res => {
              console.log("Cancel Response = " + res)
              this.cancelResponse = res;
                //calling getEvents for objects again to refresh the table
                //-------------------
                var inputForGetEventsForObject = {};
                inputForGetEventsForObject.pointAddressId = this.selectedAddress.id
                inputForGetEventsForObject.echoAPIUsername = this.echoAPIUsername
                inputForGetEventsForObject.wasteTypeIds = this.wasteTypesIdList
                inputForGetEventsForObject.eventTypeId = this.eventTypeId
                inputForGetEventsForObject.eventStateList = this.eventStates
                inputForGetEventsForObject.sizeDataTypeId = this.sizeDataTypeId
                inputForGetEventsForObject.sizeLabelList = this.sizeLabelList
                inputForGetEventsForObject.isFinancialYear = this.usingFinancialYear
                fetch('/api/messages/getEventsForObject',
                    {
                        method:'post',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(inputForGetEventsForObject)
                }
                )
                .then(res => res.json())
                .then(res => {
                    this.eventList = res
                    this.showWasteTypes=true
                })
                .catch(err => {
                     console.log(err)
                })
                //------------------------
              this.cancelDialog = false
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))
        } else {
            this.cancelDialog = true
        }
      },
      submit () {
        this.isLoading = true
        var payload = {}
        payload.guid = this.reservationGuid
        payload.pointAddress = this.selectedAddress.sharedRef
        payload.quantityList = this.wasteTypesList
        if (!(this.selectedStrataNumber)) {
          payload.name = this.firstName + " " + this.surname
        } else {
          payload.name = this.strataManager
        }
        payload.emailAddress = this.email
        payload.contactNumber = this.phoneNumber
        payload.size = this.selectedSize
        payload.wasteTypes = this.selectedWasteTypes
        payload.onDemandServiceId = this.onDemandServiceId
        payload.eventTypeId = this.eventTypeId
        payload.taskReservations = []
        console.log(this.selectedSlot)
        console.log(this.secondSelectedSlot)
        payload.taskReservations = this.selectedSlot
        payload.sizeDataTypeId = this.sizeDataTypeId
        payload.nameDataTypeId = this.nameDataTypeId
        payload.stickerLetterDatatypeId = this.stickerLetterDatatypeId
        payload.postalAddressParentObjectId = this.postalAddressParentObjectId
        payload.postalAddressLine1DataTypeId = this.postalAddressLine1DataTypeId
        payload.postalAddressLine2DataTypeId = this.postalAddressLine2DataTypeId
        payload.postalAddressSuburbDataTypeId = this.postalAddressSuburbDataTypeId
        payload.postalAddressStateDataTypeId = this.postalAddressStateDataTypeId
        payload.postalAddressPostCodeDataTypeId = this.postalAddressPostCodeDataTypeId
        payload.phoneNumberDataTypeId = this.phoneNumberDataTypeId
        payload.emailAddressDataTypeId = this.emailAddressDataTypeId
        payload.driversNotesDataTypeId = this.driversNotesDataTypeId
        payload.raisedByDataTypeId = this.raisedByDataTypeId
        payload.raisedBy = this.raisedBy
        if (this.secondDatePicker) {
          if (this.selectedSlot != null) {
            payload.taskReservations = [...this.selectedSlot, ...this.secondSelectedSlot]
          } else {
            payload.taskReservations = this.secondSelectedSlot
          }
        }
        payload.echoAPIUsername = this.echoAPIUsername

        if ((this.postalAddressPOBoxFlag) && (this.postalAddressPOBox)) {
          payload.postalAddress = this.postalAddressPOBox
        } else if (this.postalAddressFlag) {
          payload.postalAddress = this.selectedPostalAddress.description
        }

        payload.driversNote = this.collectionInstructions

        payload.stickerRequired = false
        if (this.displayStickerLetterTickbox || this.displayStickerLetterTickboxStrata) {
          if (this.selectedStrataNumber) {
            if (this.strataRequireStickerFlag) {
              payload.stickerRequired = true
            }
          } else {
            if (this.nonStrataRequireStickerFlag) {
              payload.stickerRequired = true
            }
          }
        }
        console.log("Submit Payload = " + JSON.stringify(payload))

        var request = JSON.stringify(payload)
        fetch('/api/messages/postEvent',
            {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: request
            }
        )
            .then(res => res.json())
            .then(res => {
              this.postResponse = res
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))

      },
      isCurrentDate: function(date){
          var testDate = new Date(date)
          var currentDate = new Date()
          var currentYear = currentDate.getFullYear()
          var currentMonth = currentDate.getMonth()
          var currentDay = currentDate.getDay()
          var year = testDate.getYear()
          var month = testDate.getMonth()
          var day = testDate.getDay()

          //console.log("isCurrentDate: ", currentYear == year && currentMonth == month && currentDay == day)
          return (currentYear == year && currentMonth == month && currentDay == day)
      },
      hasBeenBooked: function(date) {
          var testDate = new Date(date)
          var isBooked = false;
          if(this.eventList.length > 0){
                //Check if all entries for that day is cancelled
                this.eventList.forEach((item) =>{
                    var date = new Date(item.date)
                    if(date.getTime() == testDate.getTime()){
                        if(item.status === "Allocated to Crew"){
                            isBooked = true;
                        }
                    }
                })
           }
          return isBooked;
      },
      sameDayBookingList: function(){
          var sameDayList = [];
          if (this.availableSlots == null) {
            return null
          }
          this.availableSlotsList.forEach((item)=>{
              let hasBeenBooked = this.hasBeenBooked(item.endDate)
              if(!hasBeenBooked){
                  sameDayList.push(item);
              }
          })
          return sameDayList;
      }



  }
}
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
function getRequestParam() {
    var pathArray = window.location.pathname.split('/');
    return pathArray[1]
}
function getLabel(organization, labelList, field) {
    //console.log(field)
    var orgFields = labelList[organization]
    var result = orgFields[field]
    //console.log(result)
    return result
}
function isCollectionRequestedTooLarge(selectedSize, selectedMonth, currentYearRemaining, nextYearRemaining, allocationSummaryList, useFinancialYear) {
        //console.log("selectedSize=" + selectedSize)
        //console.log("selectedMonth=" + selectedMonth)
        //console.log("currentYearRemaining=" + currentYearRemaining)
        //console.log("nextYearRemaining=" + nextYearRemaining)
        //console.log("allocationSummaryList=" + allocationSummaryList)
        if ((selectedMonth) && (selectedSize)) {
            //Check if the current selected year has enough collections.
            var selectedYear = parseInt(selectedMonth.split('-')[0], 10)
            var parsedMonth = parseInt(selectedMonth.split('-')[1], 10)
            var currentDate = new Date()
            var currentYear = currentDate.getFullYear();
            var currentMonth = currentDate.getMonth();
            currentMonth ++;
            var isCurrentYear = false;
            if (useFinancialYear) {
                if ((selectedYear == currentYear)) {
                    if ((parsedMonth >= 1) && (parsedMonth <=6 )) {
                        if ((currentMonth >= 1) &&(currentMonth <= 6)){
                            isCurrentYear = true
                        }
                    } else if ((parsedMonth > 6) && (parsedMonth <= 12)) {
                        if ((currentMonth > 6) &&(currentMonth <= 12)){
                            isCurrentYear = true
                        }
                    }
                } else if ((selectedYear - currentYear) == 1) {
                    if ((parsedMonth >= 1) && (parsedMonth <=6 )) {
                        if (currentMonth > 6 && currentMonth <=12) {
                            isCurrentYear = true
                        }
                    }
                }

            } else {
                if (selectedYear > currentYear) {
                    isCurrentYear = false
                } else {
                    isCurrentYear = true
                }
            }

            console.log("isCurrentYear=" + isCurrentYear)
            var collectionsRemain = currentYearRemaining;
            if (!isCurrentYear) {
                collectionsRemain = nextYearRemaining
            }
            //console.log("collectionsRemain=" + collectionsRemain)
            if (collectionsRemain != null) {
                if (selectedSize > collectionsRemain) {
                    return true
                } else {
                    return false
                }
            } else {
                //Checking alloationSummaryList
                for (let i=0; i < allocationSummaryList.length; i++) {
                    var allocation = allocationSummaryList[i];
                    collectionsRemain = allocation.currentYearRemaining;
                    if (!isCurrentYear) {
                        collectionsRemain = allocation.nextYearRemaining;
                    }
                    if (selectedSize > collectionsRemain) {
                        return true;
                    }
                }
                return false

            }
        } else {
            return false
        }
}



</script>

